import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService  } from 'ngx-cookie-service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private cookie: CookieService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      console.log(this.router.url);
      //if(this.router.url !="/customerdashboard") {
          // Login checking
          let $loginCheck = false;
          if ( this.cookie.check("token")) {
            let token = this.cookie.get("token");
            if (!!token) {
              $loginCheck = true;
            }
          }

          if(!$loginCheck) {
            this.router.navigateByUrl('/login');
          }
      //}

    });
  }
}
